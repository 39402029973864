.AboutUs {
  padding: 65px 52px 10px 52px;
  min-height: 700px;
  margin: 20px 100px;
  background-color: #fff;
  transition: transform 250ms;
}
.AboutUs:hover {
  transform: translateY(-10px);
}
.AboutUs .heading {
  margin-bottom: 45px;
}
.AboutUs .h2 {
  font-size: 64px;
  line-height: 1.03;
  text-align: center;
  color: #000;
  font-family: 'Heldane Display';
  max-width: 670px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.AboutUs .AboutUsPoint .paragraph {
  font-size: 20px;
  line-height: 1.25;
  color: #000;
}
.AboutUs .AboutUsPoint .images {
  position: absolute;
  top: 119px;
  left: 182px;
}
.AboutUs .AboutUsPoint.fair .images {
  position: absolute;
  top: 75px;
  left: 25px;
}
.AboutUs .AboutUsPoint.data .images {
  top: 80px;
  left: 20px;
}
.AboutUs .AboutUsPoint.professional .images {
  top: 0px;
  left: 75px;
}
.AboutUs .AboutUsPoint.professional .images img {
  width: 450px;
  height: 490px;
  object-fit: cover;
}
.AboutUs .AboutUsPoint.professional {
  padding-left: 59px;
}
.AboutUs .AboutUsPoint.data .paragraph,
.AboutUs .AboutUsPoint.fair .paragraph {
  padding-right: 50px;
  padding-bottom: 50px;
  text-align: left;
}
.AboutUs .about-founder img {
  width: 450px;
  height: 490px;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .AboutUs .h2 {
    font-size: 54px;
  }
  .AboutUs .AboutUsPoint.data .paragraph,
  .AboutUs .AboutUsPoint.fair .paragraph {
    padding-right: 0;
  }
  .AboutUs .about-founder img {
    width: auto;
    height: 490px;
    object-fit: cover;
  }
  .AboutUs {
    min-height: 700px;
    margin: 10px;
  }
}
@media (max-width: 991px) {
  .AboutUs .h2 {
    font-size: 42px;
  }
  .AboutUs .about-founder img {
    width: auto;
    height: 490px;
    object-fit: cover;
  }
}
@media (max-width: 767px) {
  .AboutUs .about-founder img {
    width: auto;
    height: 490px;
    object-fit: cover;
  }
  .AboutUs .AboutUsPoint.data {
    order: 3;
  }
  .AboutUs .AboutUsPoint.professional {
    order: 4;
  }
  .AboutUs {
    border-top: solid 1px #e3e7ed;
  }
  .AboutUs .h2 {
    font-size: 48px;
    line-height: 1.17;
  }
  .AboutUs .AboutUsPoint .paragraph {
    line-height: 1.25;
  }
  .AboutUs .AboutUsPoint {
    width: 100%;
  }
  .AboutUs .AboutUsPoint.professional {
    padding-left: 0;
  }
  .AboutUs .AboutUsPoint.professional .images img {
    width: 505px;
  }
  .AboutUs .AboutUsPoint.patient .images {
    left: 87px;
    top: 12px;
  }
  .AboutUs .AboutUsPoint .images .h3 {
    top: -249px;
    font-size: 86.4px;
    line-height: 0.76;
    letter-spacing: -1.73px;
  }
  .AboutUs .AboutUsPoint.fair .h3 {
    margin-top: 250px;
  }
  .AboutUs .about-founder img {
    height: 550px;
  }
}
@media (max-width: 360px) {
  .AboutUs .about-founder img {
    height: 550px;
  }
}
@media (max-width: 767px) and (min-width: 346px) {
  .AboutUs .AboutUsPoint.professional {
    overflow: hidden;
  }
}
