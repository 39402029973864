/* .header {
  background-color: black;
  height: 64px;
  width: 100%;
} */
.Navbar .logo {
  width: 158px;
}
.Navbar .logo a {
  position: relative;
  top: 5px;
}
.Navbar .logo img {
  width: 220px;
}
.Navbar .MuiAppBar-colorPrimary {
  background-color: black !important;
}
.Navbar .main-tabs {
  margin: 0 10px;
  cursor: pointer;
}
