.Work {
  padding: 65px 0px 0px 0px;
  min-height: 700px;
  margin: 20px 100px;
  background-color: #fff;
  transition: transform 250ms;
}
.Work:hover {
  transform: translateY(-10px);
}
.Work .h2 {
  font-size: 64px;
  line-height: 1.03;
  text-align: center;
  color: #000;
  font-family: 'Heldane Display';
  max-width: 670px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
}
.Work .smallSection {
  padding: 0px 52px 52px 52px;
}
.Work .imgWrapper {
  width: 33.33%;
  height: 304px;
  object-fit: cover;
  margin-top: 4px;
}
.Work .imgWrapper img {
  height: 302px;
  object-fit: cover;
  width: 100%;
}
/* .Work .viewMoreWrapper {
  position: relative;
} */
.Work .viewMoreWrapper .view-more {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.Work .viewMoreWrapper .view-more .view-more-text {
  margin: auto 0px auto auto;
}
.Work .viewMoreWrapper .view-more .view-more-icon {
  margin: auto auto auto 0px;
}
.Work .viewMoreWrapper .view-more img {
  width: 42px;
  height: 42px;
  margin-left: 10px;
}
@media (max-width: 1200px) {
  .Work {
    margin: 10px;
  }
}
@media (max-width: 991px) {
  /* .Work {
    font-size: 54px;
  } */
  .Work .h2 {
    font-size: 42px;
  }
  .Work .viewMoreWrapper .view-more {
    min-height: 200px;
  }
}
@media (max-width: 767px) {
  /* .Work {
    font-size: 54px;
  } */
}
