.Footer {
  display: block;
  min-height: 200px;
  background-color: black;
}
.Footer .logo {
  margin-top: 75px;
}
.Footer .logo img {
  width: 220px;
}
.Footer .social-media {
  margin-top: 75px;
}
.Footer .rights {
  color: #808080;
  text-align: center;
  margin-top: auto;
}
.Footer .social-icon {
  height: 32px;
  width: 32px;
}
.Footer .MuiIconButton-root {
  padding: 0;
  margin: 10px;
}
.particles-bg-canvas-self {
  top: auto !important;
  bottom: 0 !important;
  height: 200px !important;
  position: relative !important;
}
@media (max-width: 899px) {
  .Footer .social-media {
    margin: 32px 0px;
  }
}
