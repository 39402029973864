/* .photoSlider {
  background-color: #ddd;
  height: 584px;
  width: 100%;
} */
/* old - .photoSlider .cover-photo {
  width: 100%;
  max-height: 584px;
  object-fit: cover;
  margin-bottom: 20px;
} */
/* .photoSlider .cover-photo {
  width: 100%;
  height: 292px;
  max-height: 292px;
  object-fit: cover;
} */

.photoSlider {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.photoSlider .title .h2 {
  font-size: 48px;
  line-height: 1.04;
  color: #000;
  font-family: 'Heldane Display';
}
.photoSlider .team-slider .team-slider-content {
  padding: 0 0px;
}
.photoSlider .slick-slide img {
  object-fit: cover;
  height: 655px;
}
.slick-prev {
  left: 2% !important;
  z-index: 1;
}
.slick-next {
  right: 2% !important;
  z-index: 1;
}
.slick-dots {
  bottom: -20px;
}
@media (max-width: 899px) {
  .slick-dots {
    bottom: -16px;
  }
}
